// --------------------------------------------------------------------------
// CARD COMPONENT
// --------------------------------------------------------------------------
//
// Cardy
//

/**
 * DEPENDENCIES
 * - none
 */


// --------------------------------------------------------------------------
// VARIABLES
// --------------------------------------------------------------------------


// --------------------------------------------------------------------------
// FUNCTIONS
// --------------------------------------------------------------------------



// --------------------------------------------------------------------------
// MIXINS
// --------------------------------------------------------------------------



// --------------------------------------------------------------------------
// STYLING
// --------------------------------------------------------------------------

.Card {
	display: block;
	position: relative;
	padding: triple($Base-spacing-unit);

	color: $Colour-black;
	background-color: $Colour-bg;
	border-radius: 4px;
	text-decoration: none;

	+ .Card {
		margin-top: double($Base-spacing-unit);
	}
}

a.Card {
	color: inherit;
}
