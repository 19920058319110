// --------------------------------------------------------------------------
// HEADINGS ELEMENT
// --------------------------------------------------------------------------

/**
 * Intro text
 *
 *
 * DEPENDENCIES
 * - base/type
 *
 *
 * USED WITH
 * -
 * 
 */

 // Styleguide 19.1.0.



// --------------------------------------------------------------------------
// VARIABLES
// --------------------------------------------------------------------------



// --------------------------------------------------------------------------
// FUNCTIONS
// --------------------------------------------------------------------------



// --------------------------------------------------------------------------
// MIXINS
// --------------------------------------------------------------------------



// --------------------------------------------------------------------------
// EXTENDABLES
// --------------------------------------------------------------------------



// --------------------------------------------------------------------------
// STYLING
// --------------------------------------------------------------------------

h1,
h2,
h3,
h4,
h5 {
	@include conditional( $c-typeHeading-marginFirstChild ) {
		&:first-child {
			margin: $c-typeHeading-marginFirstChild
		}
	};
}

h1 {
	@include type($FontSize-h1, $LineHeight-h1, $FontWeight-bold);
}

h2 {
	@include type($FontSize-h2, $LineHeight-h2, $FontWeight-bold);
}

h3 {
	@include type($FontSize-h3, $LineHeight-h3, $FontWeight-bold);
}

h4 {
	@include type($FontSize-h4, $LineHeight-h4, $FontWeight-bold);
}

h5 {
	@include type($FontSize-h5, $LineHeight-h5, $FontWeight-bold);
	text-transform: uppercase;
}