.Tabs-nav {
   border-bottom: 1px solid $Colour-border;

   ul {
      padding-left: 0;
   }
}

.Tabs-navItem {
   display: inline-block;
   padding: $BSU triple($BSU);
   border-bottom: 1px solid transparent;
   cursor: pointer;

   &:hover a {
      color: $Colour-black;
   }

   &.is-active {
      color: $Colour-text;
      border-bottom-color: $Colour-base;

      a {
         color: $Colour-black;
      }
   }

   a {
      color: $Colour-text;
      text-decoration: none;
   }

   + .Tabs-navItem {
      // margin
   }
}

.Tabs-content {
   padding: $BSU * 3;
}
