// --------------------------------------------------------------------------
// LINK ELEMENT
// --------------------------------------------------------------------------

/**
 * Intro text
 *
 *
 * DEPENDENCIES
 * - 
 *
 *
 * USED WITH
 * -
 * 
 */

 // Styleguide 20.1.0.



// --------------------------------------------------------------------------
// VARIABLES
// --------------------------------------------------------------------------

$c-link-colour: $Colour-primary !default;
$c-link-colour--hover: $Colour-base !default;

$c-link-outline: 0;



// --------------------------------------------------------------------------
// FUNCTIONS
// --------------------------------------------------------------------------



// --------------------------------------------------------------------------
// MIXINS
// --------------------------------------------------------------------------



// --------------------------------------------------------------------------
// EXTENDABLES
// --------------------------------------------------------------------------

%link {
	@include conditional( $c-link-colour ) { color: $c-link-colour };

	&:hover,
	&:focus {
		@include conditional( $c-link-outline ) { outline: $c-link-outline };
		@include conditional( $c-link-colour--hover ) { color: $c-link-colour--hover };
	}
}



// --------------------------------------------------------------------------
// STYLING
// --------------------------------------------------------------------------

@if $use-element-links == true {

	a {
		@extend %link;
	}

}