// --------------------------------------------------------------------------
// ISLAND COMPONENT
// --------------------------------------------------------------------------
//
// An island can be using instead of grid for layout
//

/**
 * DEPENDENCIES
 * - none
 */


// --------------------------------------------------------------------------
// VARIABLES
// --------------------------------------------------------------------------


// --------------------------------------------------------------------------
// FUNCTIONS
// --------------------------------------------------------------------------



// --------------------------------------------------------------------------
// MIXINS
// --------------------------------------------------------------------------



// --------------------------------------------------------------------------
// STYLING
// --------------------------------------------------------------------------

.Island {
	@include grid();

	padding-top: ($Base-spacing-unit * 12);
	padding-bottom: ($Base-spacing-unit * 12);

	@include media('>=tab') {
		padding-top: $Base-spacing-unit * 18;
		padding-bottom: $Base-spacing-unit * 18;
	}

	@include media('>=lap') {
		padding-top: $Base-spacing-unit * 27;
		padding-bottom: $Base-spacing-unit * 27;
	}
}

.Island-body {
	@include grid-column();

	margin-left: auto;
	margin-right: auto;
	text-align: center;

	@include media('>=tab') {
		width: percentage(3/4);
	}

	@include media('>=lap') {
		width: percentage(2/3);
	}
}
