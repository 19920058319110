@font-face {
  font-family: 'icons';
  src:  url('./../fonts/icons.eot?io6ws1');
  src:  url('./../fonts/icons.eot?io6ws1#iefix') format('embedded-opentype'),
    url('./../fonts/icons.ttf?io6ws1') format('truetype'),
    url('./../fonts/icons.woff?io6ws1') format('woff'),
    url('./../fonts/icons.svg?io6ws1#icons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

// --------------------------------------------------------------------------
// VARIABLES
// --------------------------------------------------------------------------
$icon-scissors: "\e900";
$icon-cut: "\e900";
$icon-copy: "\e901";
$icon-duplicate: "\e901";
$icon-files: "\e901";
$icon-pages: "\e901";
$icon-papers: "\e901";
$icon-documents: "\e901";
$icon-file-empty: "\e902";
$icon-file: "\e902";
$icon-document: "\e902";
$icon-paper: "\e902";
$icon-page: "\e902";
$icon-new: "\e902";
$icon-empty: "\e902";
$icon-blank: "\e902";
$icon-chevron-down: "\e908";
$icon-chevron-left: "\e909";
$icon-chevron-right: "\e90a";
$icon-chevron-up: "\e90b";
$icon-brush: "\e903";
$icon-edit: "\e904";
$icon-flat-brush: "\e905";
$icon-pencil: "\e906";
$icon-calculator: "\ec0a";
$icon-book1: "\e93b";
$icon-clipboard-edit: "\e93e";
$icon-clipboard: "\e944";
$icon-clipboard-text: "\e94c";
$icon-clipboard-list: "\e94e";
$icon-note: "\e950";
$icon-note-text: "\e956";
$icon-notebook: "\e95e";
$icon-notebook6: "\e964";
$icon-notebook-text: "\e966";
$icon-notebook-text1: "\e967";
$icon-document1: "\e96a";
$icon-document11: "\e96b";
$icon-document-text: "\e96c";
$icon-document-text1: "\e96d";
$icon-document-text2: "\e96e";
$icon-document-text3: "\e96f";
$icon-documents1: "\e98c";
$icon-documents11: "\e98d";
$icon-documents2: "\e98e";
$icon-documents3: "\e98f";
$icon-pen: "\ea08";
$icon-pen1: "\ea09";
$icon-pen-angled: "\ea0a";
$icon-pen-angled1: "\ea0b";
$icon-document-edit: "\ea0c";
$icon-document-edit1: "\ea0d";
$icon-trophy: "\ea2e";
$icon-trophy1: "\ea2f";
$icon-trophy-one: "\ea30";
$icon-trophy-one1: "\ea31";
$icon-trophy2: "\ea32";
$icon-trophy3: "\ea33";
$icon-medal: "\ea34";
$icon-medal1: "\ea35";
$icon-medal2: "\ea36";
$icon-medal3: "\ea37";
$icon-stop-watch: "\ea38";
$icon-stop-watch1: "\ea39";
$icon-stop-watch2: "\ea3a";
$icon-stop-watch3: "\ea3b";
$icon-award2: "\ea42";
$icon-award3: "\ea43";
$icon-award4: "\ea44";
$icon-award5: "\ea45";
$icon-award6: "\ea46";
$icon-award7: "\ea47";
$icon-medal4: "\ea48";
$icon-medal5: "\ea49";
$icon-medal6: "\ea4a";
$icon-medal7: "\ea4b";
$icon-sports-shoe: "\e907";
$icon-wind-turbine: "\eae0";
$icon-wind-turbine1: "\eae1";
$icon-snowflake: "\eae2";
$icon-snowflake1: "\eae3";
$icon-flashed-face: "\eae4";
$icon-flashed-face1: "\eae5";
$icon-flashed-face2: "\eae6";
$icon-flashed-face3: "\eae7";
$icon-flashed-face-glasses: "\eae8";
$icon-flashed-face-glasses1: "\eae9";
$icon-face-missing-moth: "\eaea";
$icon-face-missing-moth1: "\eaeb";
$icon-neutral-face: "\eaec";
$icon-neutral-face1: "\eaed";
$icon-smiling-face: "\eaee";
$icon-smiling-face1: "\eaef";
$icon-sad-face: "\eaf0";
$icon-sad-face1: "\eaf1";
$icon-face-open-mouth: "\eaf2";
$icon-face-open-mouth1: "\eaf3";
$icon-face-open-mouth2: "\eaf4";
$icon-face-open-mouth3: "\eaf5";
$icon-winking-face: "\eaf6";
$icon-winking-face1: "\eaf7";
$icon-laughing-face: "\eaf8";
$icon-laughing-face1: "\eaf9";
$icon-laughing-face2: "\eafa";
$icon-laughing-face3: "\eafb";
$icon-smirking-face: "\eafc";
$icon-smirking-face1: "\eafd";
$icon-stubborn-face: "\eafe";
$icon-stubborn-face1: "\eaff";
$icon-neutral-face2: "\eb00";
$icon-neutral-face3: "\eb01";
$icon-sad-face2: "\eb02";
$icon-sad-face3: "\eb03";
$icon-smiling-face2: "\eb04";
$icon-smiling-face3: "\eb05";
$icon-smiling-face-eyebrows: "\eb06";
$icon-smiling-face-eyebrows1: "\eb07";
$icon-grinning-face-eyebrows: "\eb08";
$icon-grinning-face-eyebrows1: "\eb09";
$icon-sad-face-eyebrows: "\eb0a";
$icon-sad-face-eyebrows1: "\eb0b";
$icon-neutral-face-eyebrows: "\eb0c";
$icon-neutral-face-eyebrows1: "\eb0d";
$icon-angry-face: "\eb0e";
$icon-angry-face1: "\eb0f";
$icon-worried-face: "\eb10";
$icon-worried-face1: "\eb11";
$icon-winking-face2: "\eb12";
$icon-winking-face3: "\eb13";
$icon-angry-face-eyebrows: "\eb14";
$icon-angry-face-eyebrows1: "\eb15";
$icon-grinning-face: "\eb16";
$icon-grinning-face1: "\eb17";
$icon-sad-face4: "\eb18";
$icon-sad-face5: "\eb19";
$icon-grinning-face-eyebrows2: "\eb1a";
$icon-grinning-face-eyebrows3: "\eb1b";
$icon-fake-grinning-face-eyebrows: "\eb1c";
$icon-fake-grinning-face-eyebrows1: "\eb1d";
$icon-worried-face-eyebrows: "\eb1e";
$icon-worried-face-eyebrows1: "\eb1f";
$icon-face-stuck-out-tongue: "\eb20";
$icon-face-stuck-out-tongue1: "\eb21";
$icon-face-stuck-out-tongue2: "\eb22";
$icon-face-stuck-out-tongue3: "\eb23";
$icon-kissing-face: "\eb24";
$icon-kissing-face1: "\eb25";
$icon-grinning-face-teeth: "\eb26";
$icon-grinning-face-teeth1: "\eb27";
$icon-angry-face-teeth: "\eb28";
$icon-angry-face-teeth1: "\eb29";
$icon-worried-face-teeth: "\eb2a";
$icon-worried-face-teeth1: "\eb2b";
$icon-grinning-face-teeth2: "\eb2c";
$icon-grinning-face-teeth3: "\eb2d";
$icon-face-open-mouth-eyebrows: "\eb2e";
$icon-face-open-mouth-eyebrows1: "\eb2f";
$icon-face-open-mouth-eyebrows2: "\eb30";
$icon-face-open-mouth-eyebrows3: "\eb31";
$icon-angry-face-open-mouth-eyebrows: "\eb32";
$icon-angry-face-open-mouth-eyebrows1: "\eb33";
$icon-unamused-face-tightly-closed-eyes: "\eb34";
$icon-unamused-face-tightly-closed-eyes1: "\eb35";
$icon-sad-face--tightly-closed-eyes: "\eb36";
$icon-sad-face--tightly-closed-eyes1: "\eb37";
$icon-kissing-face2: "\eb38";
$icon-kissing-face3: "\eb39";
$icon-face-closed-meyes: "\eb3a";
$icon-face-closed-meyes1: "\eb3b";
$icon-amused-face: "\eb3c";
$icon-amused-face1: "\eb3d";
$icon-amused-face-closed-eyes: "\eb3e";
$icon-amused-face-closed-eyes1: "\eb3f";
$icon-amused-face-closed-eyes2: "\eb40";
$icon-amused-face-closed-eyes3: "\eb41";
$icon-face-closed-eyes-open-mouth: "\eb42";
$icon-face-closed-eyes-open-mouth1: "\eb43";
$icon-face-closed-eyes-open-mouth2: "\eb44";
$icon-face-closed-eyes-open-mouth3: "\eb45";
$icon-face-closed-eyes-open-mouth4: "\eb46";
$icon-face-closed-eyes-open-mouth5: "\eb47";
$icon-laughing-face4: "\eb48";
$icon-laughing-face5: "\eb49";
$icon-smiling-face4: "\eb4a";
$icon-smiling-face5: "\eb4b";
$icon-grinning-face2: "\eb4c";
$icon-grinning-face3: "\eb4d";
$icon-sad-face6: "\eb4e";
$icon-sad-face7: "\eb4f";
$icon-sad-face8: "\eb50";
$icon-sad-face9: "\eb51";
$icon-sad-face-closed-eyes: "\eb52";
$icon-sad-face-closed-eyes1: "\eb53";
$icon-sad-face10: "\eb54";
$icon-sad-face11: "\eb55";
$icon-smiling-face6: "\eb56";
$icon-smiling-face7: "\eb57";
$icon-astonished-face: "\eb58";
$icon-astonished-face1: "\eb59";
$icon-astonished-face2: "\eb5a";
$icon-astonished-face3: "\eb5b";
$icon-face-moustache: "\eb5c";
$icon-face-moustache1: "\eb5d";
$icon-face-moustache2: "\eb5e";
$icon-face-moustache3: "\eb5f";
$icon-face-glasses: "\eb60";
$icon-face-glasses1: "\eb61";
$icon-face-sunglasses: "\eb62";
$icon-face-sunglasses1: "\eb63";
$icon-smirking-face-sunglasses: "\eb64";
$icon-smirking-face-sunglasses1: "\eb65";
$icon-middle-finger: "\eb66";
$icon-middle-finger1: "\eb67";
$icon-rock-n-roll: "\eb68";
$icon-rock-n-roll1: "\eb69";
$icon-high-five: "\eb6a";
$icon-high-five1: "\eb6b";
$icon-thumb-up: "\eb6c";
$icon-thumb-up1: "\eb6d";
$icon-thumb-down: "\eb6e";
$icon-thumb-down1: "\eb6f";
$icon-thumb-up2: "\eb70";
$icon-thumb-up3: "\eb71";
$icon-thumb-down2: "\eb72";
$icon-thumb-down3: "\eb73";
$icon-two-fingers-swipe-left: "\eb74";
$icon-two-fingers-swipe-left1: "\eb75";
$icon-two-fingers-swipe-right: "\eb76";
$icon-two-fingers-swipe-right1: "\eb77";
$icon-two-fingers-swipe-up: "\eb78";
$icon-two-fingers-swipe-up1: "\eb79";
$icon-two-fingers-swipe-down: "\eb7a";
$icon-two-fingers-swipe-down1: "\eb7b";
$icon-two-fingers: "\eb7c";
$icon-two-fingers1: "\eb7d";
$icon-three-fingers-double-tap: "\eb7e";
$icon-three-fingers-double-tap1: "\eb7f";
$icon-two-fingers-resize-out: "\eb80";
$icon-two-fingers-resize-out1: "\eb81";
$icon-two-fingers-resize-in: "\eb82";
$icon-two-fingers-resize-in1: "\eb83";
$icon-two-fingers-rotate: "\eb84";
$icon-two-fingers-rotate1: "\eb85";
$icon-one-finger-swipe-left: "\eb86";
$icon-one-finger-swipe-left1: "\eb87";
$icon-one-finger-swipe-right: "\eb88";
$icon-one-finger-swipe-right1: "\eb89";
$icon-one-finger-swipe-up: "\eb8a";
$icon-one-finger-swipe-up1: "\eb8b";
$icon-one-finger-swipe-down: "\eb8c";
$icon-one-finger-swipe-down1: "\eb8d";
$icon-one-finger: "\eb8e";
$icon-one-finger1: "\eb8f";
$icon-one-finger-double-tap: "\eb90";
$icon-one-finger-double-tap1: "\eb91";
$icon-one-finger-tap: "\eb92";
$icon-one-finger-tap1: "\eb93";
$icon-one-finger-tap-hold: "\eb94";
$icon-one-finger-tap-hold1: "\eb95";
$icon-thumb-finger-tap: "\eb96";
$icon-thumb-finger-tap1: "\eb97";
$icon-one-finger-click: "\eb98";
$icon-one-finger-click1: "\eb99";
$icon-three-fingers-swipe-left: "\eb9a";
$icon-three-fingers-swipe-left1: "\eb9b";
$icon-three-fingers-swipe-right: "\eb9c";
$icon-three-fingers-swipe-right1: "\eb9d";
$icon-three-fingers-swipe-up: "\eb9e";
$icon-three-fingers-swipe-up1: "\eb9f";
$icon-three-fingers-swipe-down: "\eba0";
$icon-three-fingers-swipe-down1: "\eba1";
$icon-three-fingers: "\eba2";
$icon-three-fingers1: "\eba3";
$icon-three-fingers-double-tap2: "\eba4";
$icon-three-fingers-double-tap3: "\eba5";
$icon-two-fingers-swipe-up2: "\eba6";
$icon-two-fingers-swipe-up3: "\eba7";
$icon-one-finger-double-tap2: "\eba8";
$icon-one-finger-double-tap3: "\eba9";
$icon-two-fingers-swipe-down2: "\ebaa";
$icon-two-fingers-swipe-down3: "\ebab";
$icon-two-fingers-swipe-right2: "\ebac";
$icon-two-fingers-swipe-right3: "\ebad";
$icon-two-fingers-swipe-left2: "\ebae";
$icon-two-fingers-swipe-left3: "\ebaf";
$icon-one-finger-tap2: "\ebb0";
$icon-one-finger-tap3: "\ebb1";
$icon-one-finger-tap-hold2: "\ebb2";
$icon-one-finger-tap-hold3: "\ebb3";
$icon-one-finger-click2: "\ebb4";
$icon-one-finger-click3: "\ebb5";
$icon-one-finger-swipe-horizontally: "\ebb6";
$icon-one-finger-swipe-horizontally1: "\ebb7";
$icon-one-finger-swipe: "\ebb8";
$icon-one-finger-swipe1: "\ebb9";
$icon-two-fingers-double-tap: "\ebba";
$icon-two-fingers-double-tap1: "\ebbb";
$icon-two-fingers-tap: "\ebbc";
$icon-two-fingers-tap1: "\ebbd";
$icon-one-finger-swipe-left2: "\ebbe";
$icon-one-finger-swipe-left3: "\ebbf";
$icon-one-finger-swipe-right2: "\ebc0";
$icon-one-finger-swipe-right3: "\ebc1";
$icon-one-finger-swipe-up2: "\ebc2";
$icon-one-finger-swipe-up3: "\ebc3";
$icon-one-finger-swipe-down2: "\ebc4";
$icon-one-finger-swipe-down3: "\ebc5";
$icon-file-png: "\ebc6";
$icon-file-png1: "\ebc7";
$icon-file-pdf: "\ebc8";
$icon-file-pdf1: "\ebc9";
$icon-file-mp3: "\ebca";
$icon-file-mp31: "\ebcb";
$icon-file-mp4: "\ebcc";
$icon-file-mp41: "\ebcd";
$icon-file-mov: "\ebce";
$icon-file-mov1: "\ebcf";
$icon-file-jpg: "\ebd0";
$icon-file-jpg1: "\ebd1";
$icon-file-html: "\ebd2";
$icon-file-html1: "\ebd3";
$icon-file-doc: "\ebd4";
$icon-file-doc1: "\ebd5";
$icon-file-xls: "\ebd6";
$icon-file-xls1: "\ebd7";
$icon-file-docx: "\ebd8";
$icon-file-docx1: "\ebd9";
$icon-file-ppt: "\ebda";
$icon-file-ppt1: "\ebdb";
$icon-file-xlsx: "\ebdc";
$icon-file-xlsx1: "\ebdd";
$icon-file-m4v: "\ebde";
$icon-file-m4v1: "\ebdf";
$icon-file-mpg: "\ebe0";
$icon-file-mpg1: "\ebe1";
$icon-file-zip: "\ebe2";
$icon-file-zip1: "\ebe3";
$icon-file-gif: "\ebe4";
$icon-file-gif1: "\ebe5";
$icon-document-file-png: "\ebe6";
$icon-document-file-png1: "\ebe7";
$icon-document-file-pdf: "\ebe8";
$icon-document-file-pdf1: "\ebe9";
$icon-document-file-mp3: "\ebea";
$icon-document-file-mp31: "\ebeb";
$icon-document-file-mp4: "\ebec";
$icon-document-file-mp41: "\ebed";
$icon-document-file-mov: "\ebee";
$icon-document-file-mov1: "\ebef";
$icon-document-file-jpg: "\ebf0";
$icon-document-file-jpg1: "\ebf1";
$icon-document-file-doc: "\ebf2";
$icon-document-file-doc1: "\ebf3";
$icon-document-file-xls: "\ebf4";
$icon-document-file-xls1: "\ebf5";
$icon-document-file-docx: "\ebf6";
$icon-document-file-docx1: "\ebf7";
$icon-document-file-ppt: "\ebf8";
$icon-document-file-ppt1: "\ebf9";
$icon-document-file-xlsx: "\ebfa";
$icon-document-file-xlsx1: "\ebfb";
$icon-document-file-txt: "\ebfc";
$icon-document-file-txt1: "\ebfd";
$icon-document-file-m4v: "\ebfe";
$icon-document-file-m4v1: "\ebff";
$icon-document-file-mpg: "\ec00";
$icon-document-file-mpg1: "\ec01";
$icon-document-file-aac: "\ec02";
$icon-document-file-aac1: "\ec03";
$icon-document-file-wav: "\ec04";
$icon-document-file-wav1: "\ec05";
$icon-document-file-zip: "\ec06";
$icon-document-file-zip1: "\ec07";
$icon-document-file-gif: "\ec08";
$icon-document-file-gif1: "\ec09";


// --------------------------------------------------------------------------
// MIXINS
// --------------------------------------------------------------------------
@mixin icon-font() {
   /* use !important to prevent issues with browser extensions that change fonts */
   font-family: 'icons' !important;
   speak: none;
   font-style: normal;
   font-weight: normal;
   font-variant: normal;
   text-transform: none;
   line-height: 1;

   /* Better Font Rendering =========== */
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
}


// --------------------------------------------------------------------------
// STYLING
// --------------------------------------------------------------------------
.icon [class^="icon-"], [class*=" icon-"] {
  @include icon-font();
}

.icon--l {
   font-size: 150%;
}

.icon--media {
   @include font-size(40px);
}

.icon-scissors {
  &:before {
    content: $icon-scissors;
  }
}
.icon-cut {
  &:before {
    content: $icon-cut;
  }
}
.icon-copy {
  &:before {
    content: $icon-copy;
  }
}
.icon-duplicate {
  &:before {
    content: $icon-duplicate;
  }
}
.icon-files {
  &:before {
    content: $icon-files;
  }
}
.icon-pages {
  &:before {
    content: $icon-pages;
  }
}
.icon-papers {
  &:before {
    content: $icon-papers;
  }
}
.icon-documents {
  &:before {
    content: $icon-documents;
  }
}
.icon-file-empty {
  &:before {
    content: $icon-file-empty;
  }
}
.icon-file {
  &:before {
    content: $icon-file;
  }
}
.icon-document {
  &:before {
    content: $icon-document;
  }
}
.icon-paper {
  &:before {
    content: $icon-paper;
  }
}
.icon-page {
  &:before {
    content: $icon-page;
  }
}
.icon-new {
  &:before {
    content: $icon-new;
  }
}
.icon-empty {
  &:before {
    content: $icon-empty;
  }
}
.icon-blank {
  &:before {
    content: $icon-blank;
  }
}
.icon-chevron-down {
  &:before {
    content: $icon-chevron-down;
  }
}
.icon-chevron-left {
  &:before {
    content: $icon-chevron-left;
  }
}
.icon-chevron-right {
  &:before {
    content: $icon-chevron-right;
  }
}
.icon-chevron-up {
  &:before {
    content: $icon-chevron-up;
  }
}
.icon-brush {
  &:before {
    content: $icon-brush;
  }
}
.icon-edit {
  &:before {
    content: $icon-edit;
  }
}
.icon-flat-brush {
  &:before {
    content: $icon-flat-brush;
  }
}
.icon-pencil {
  &:before {
    content: $icon-pencil;
  }
}
.icon-calculator {
  &:before {
    content: $icon-calculator;
  }
}
.icon-book1 {
  &:before {
    content: $icon-book1;
  }
}
.icon-clipboard-edit {
  &:before {
    content: $icon-clipboard-edit;
  }
}
.icon-clipboard {
  &:before {
    content: $icon-clipboard;
  }
}
.icon-clipboard-text {
  &:before {
    content: $icon-clipboard-text;
  }
}
.icon-clipboard-list {
  &:before {
    content: $icon-clipboard-list;
  }
}
.icon-note {
  &:before {
    content: $icon-note;
  }
}
.icon-note-text {
  &:before {
    content: $icon-note-text;
  }
}
.icon-notebook {
  &:before {
    content: $icon-notebook;
  }
}
.icon-notebook6 {
  &:before {
    content: $icon-notebook6;
  }
}
.icon-notebook-text {
  &:before {
    content: $icon-notebook-text;
  }
}
.icon-notebook-text1 {
  &:before {
    content: $icon-notebook-text1;
  }
}
.icon-document1 {
  &:before {
    content: $icon-document1;
  }
}
.icon-document11 {
  &:before {
    content: $icon-document11;
  }
}
.icon-document-text {
  &:before {
    content: $icon-document-text;
  }
}
.icon-document-text1 {
  &:before {
    content: $icon-document-text1;
  }
}
.icon-document-text2 {
  &:before {
    content: $icon-document-text2;
  }
}
.icon-document-text3 {
  &:before {
    content: $icon-document-text3;
  }
}
.icon-documents1 {
  &:before {
    content: $icon-documents1;
  }
}
.icon-documents11 {
  &:before {
    content: $icon-documents11;
  }
}
.icon-documents2 {
  &:before {
    content: $icon-documents2;
  }
}
.icon-documents3 {
  &:before {
    content: $icon-documents3;
  }
}
.icon-pen {
  &:before {
    content: $icon-pen;
  }
}
.icon-pen1 {
  &:before {
    content: $icon-pen1;
  }
}
.icon-pen-angled {
  &:before {
    content: $icon-pen-angled;
  }
}
.icon-pen-angled1 {
  &:before {
    content: $icon-pen-angled1;
  }
}
.icon-document-edit {
  &:before {
    content: $icon-document-edit;
  }
}
.icon-document-edit1 {
  &:before {
    content: $icon-document-edit1;
  }
}
.icon-trophy {
  &:before {
    content: $icon-trophy;
  }
}
.icon-trophy1 {
  &:before {
    content: $icon-trophy1;
  }
}
.icon-trophy-one {
  &:before {
    content: $icon-trophy-one;
  }
}
.icon-trophy-one1 {
  &:before {
    content: $icon-trophy-one1;
  }
}
.icon-trophy2 {
  &:before {
    content: $icon-trophy2;
  }
}
.icon-trophy3 {
  &:before {
    content: $icon-trophy3;
  }
}
.icon-medal {
  &:before {
    content: $icon-medal;
  }
}
.icon-medal1 {
  &:before {
    content: $icon-medal1;
  }
}
.icon-medal2 {
  &:before {
    content: $icon-medal2;
  }
}
.icon-medal3 {
  &:before {
    content: $icon-medal3;
  }
}
.icon-stop-watch {
  &:before {
    content: $icon-stop-watch;
  }
}
.icon-stop-watch1 {
  &:before {
    content: $icon-stop-watch1;
  }
}
.icon-stop-watch2 {
  &:before {
    content: $icon-stop-watch2;
  }
}
.icon-stop-watch3 {
  &:before {
    content: $icon-stop-watch3;
  }
}
.icon-award2 {
  &:before {
    content: $icon-award2;
  }
}
.icon-award3 {
  &:before {
    content: $icon-award3;
  }
}
.icon-award4 {
  &:before {
    content: $icon-award4;
  }
}
.icon-award5 {
  &:before {
    content: $icon-award5;
  }
}
.icon-award6 {
  &:before {
    content: $icon-award6;
  }
}
.icon-award7 {
  &:before {
    content: $icon-award7;
  }
}
.icon-medal4 {
  &:before {
    content: $icon-medal4;
  }
}
.icon-medal5 {
  &:before {
    content: $icon-medal5;
  }
}
.icon-medal6 {
  &:before {
    content: $icon-medal6;
  }
}
.icon-medal7 {
  &:before {
    content: $icon-medal7;
  }
}
.icon-sports-shoe {
  &:before {
    content: $icon-sports-shoe;
  }
}
.icon-wind-turbine {
  &:before {
    content: $icon-wind-turbine;
  }
}
.icon-wind-turbine1 {
  &:before {
    content: $icon-wind-turbine1;
  }
}
.icon-snowflake {
  &:before {
    content: $icon-snowflake;
  }
}
.icon-snowflake1 {
  &:before {
    content: $icon-snowflake1;
  }
}
.icon-flashed-face {
  &:before {
    content: $icon-flashed-face;
  }
}
.icon-flashed-face1 {
  &:before {
    content: $icon-flashed-face1;
  }
}
.icon-flashed-face2 {
  &:before {
    content: $icon-flashed-face2;
  }
}
.icon-flashed-face3 {
  &:before {
    content: $icon-flashed-face3;
  }
}
.icon-flashed-face-glasses {
  &:before {
    content: $icon-flashed-face-glasses;
  }
}
.icon-flashed-face-glasses1 {
  &:before {
    content: $icon-flashed-face-glasses1;
  }
}
.icon-face-missing-moth {
  &:before {
    content: $icon-face-missing-moth;
  }
}
.icon-face-missing-moth1 {
  &:before {
    content: $icon-face-missing-moth1;
  }
}
.icon-neutral-face {
  &:before {
    content: $icon-neutral-face;
  }
}
.icon-neutral-face1 {
  &:before {
    content: $icon-neutral-face1;
  }
}
.icon-smiling-face {
  &:before {
    content: $icon-smiling-face;
  }
}
.icon-smiling-face1 {
  &:before {
    content: $icon-smiling-face1;
  }
}
.icon-sad-face {
  &:before {
    content: $icon-sad-face;
  }
}
.icon-sad-face1 {
  &:before {
    content: $icon-sad-face1;
  }
}
.icon-face-open-mouth {
  &:before {
    content: $icon-face-open-mouth;
  }
}
.icon-face-open-mouth1 {
  &:before {
    content: $icon-face-open-mouth1;
  }
}
.icon-face-open-mouth2 {
  &:before {
    content: $icon-face-open-mouth2;
  }
}
.icon-face-open-mouth3 {
  &:before {
    content: $icon-face-open-mouth3;
  }
}
.icon-winking-face {
  &:before {
    content: $icon-winking-face;
  }
}
.icon-winking-face1 {
  &:before {
    content: $icon-winking-face1;
  }
}
.icon-laughing-face {
  &:before {
    content: $icon-laughing-face;
  }
}
.icon-laughing-face1 {
  &:before {
    content: $icon-laughing-face1;
  }
}
.icon-laughing-face2 {
  &:before {
    content: $icon-laughing-face2;
  }
}
.icon-laughing-face3 {
  &:before {
    content: $icon-laughing-face3;
  }
}
.icon-smirking-face {
  &:before {
    content: $icon-smirking-face;
  }
}
.icon-smirking-face1 {
  &:before {
    content: $icon-smirking-face1;
  }
}
.icon-stubborn-face {
  &:before {
    content: $icon-stubborn-face;
  }
}
.icon-stubborn-face1 {
  &:before {
    content: $icon-stubborn-face1;
  }
}
.icon-neutral-face2 {
  &:before {
    content: $icon-neutral-face2;
  }
}
.icon-neutral-face3 {
  &:before {
    content: $icon-neutral-face3;
  }
}
.icon-sad-face2 {
  &:before {
    content: $icon-sad-face2;
  }
}
.icon-sad-face3 {
  &:before {
    content: $icon-sad-face3;
  }
}
.icon-smiling-face2 {
  &:before {
    content: $icon-smiling-face2;
  }
}
.icon-smiling-face3 {
  &:before {
    content: $icon-smiling-face3;
  }
}
.icon-smiling-face-eyebrows {
  &:before {
    content: $icon-smiling-face-eyebrows;
  }
}
.icon-smiling-face-eyebrows1 {
  &:before {
    content: $icon-smiling-face-eyebrows1;
  }
}
.icon-grinning-face-eyebrows {
  &:before {
    content: $icon-grinning-face-eyebrows;
  }
}
.icon-grinning-face-eyebrows1 {
  &:before {
    content: $icon-grinning-face-eyebrows1;
  }
}
.icon-sad-face-eyebrows {
  &:before {
    content: $icon-sad-face-eyebrows;
  }
}
.icon-sad-face-eyebrows1 {
  &:before {
    content: $icon-sad-face-eyebrows1;
  }
}
.icon-neutral-face-eyebrows {
  &:before {
    content: $icon-neutral-face-eyebrows;
  }
}
.icon-neutral-face-eyebrows1 {
  &:before {
    content: $icon-neutral-face-eyebrows1;
  }
}
.icon-angry-face {
  &:before {
    content: $icon-angry-face;
  }
}
.icon-angry-face1 {
  &:before {
    content: $icon-angry-face1;
  }
}
.icon-worried-face {
  &:before {
    content: $icon-worried-face;
  }
}
.icon-worried-face1 {
  &:before {
    content: $icon-worried-face1;
  }
}
.icon-winking-face2 {
  &:before {
    content: $icon-winking-face2;
  }
}
.icon-winking-face3 {
  &:before {
    content: $icon-winking-face3;
  }
}
.icon-angry-face-eyebrows {
  &:before {
    content: $icon-angry-face-eyebrows;
  }
}
.icon-angry-face-eyebrows1 {
  &:before {
    content: $icon-angry-face-eyebrows1;
  }
}
.icon-grinning-face {
  &:before {
    content: $icon-grinning-face;
  }
}
.icon-grinning-face1 {
  &:before {
    content: $icon-grinning-face1;
  }
}
.icon-sad-face4 {
  &:before {
    content: $icon-sad-face4;
  }
}
.icon-sad-face5 {
  &:before {
    content: $icon-sad-face5;
  }
}
.icon-grinning-face-eyebrows2 {
  &:before {
    content: $icon-grinning-face-eyebrows2;
  }
}
.icon-grinning-face-eyebrows3 {
  &:before {
    content: $icon-grinning-face-eyebrows3;
  }
}
.icon-fake-grinning-face-eyebrows {
  &:before {
    content: $icon-fake-grinning-face-eyebrows;
  }
}
.icon-fake-grinning-face-eyebrows1 {
  &:before {
    content: $icon-fake-grinning-face-eyebrows1;
  }
}
.icon-worried-face-eyebrows {
  &:before {
    content: $icon-worried-face-eyebrows;
  }
}
.icon-worried-face-eyebrows1 {
  &:before {
    content: $icon-worried-face-eyebrows1;
  }
}
.icon-face-stuck-out-tongue {
  &:before {
    content: $icon-face-stuck-out-tongue;
  }
}
.icon-face-stuck-out-tongue1 {
  &:before {
    content: $icon-face-stuck-out-tongue1;
  }
}
.icon-face-stuck-out-tongue2 {
  &:before {
    content: $icon-face-stuck-out-tongue2;
  }
}
.icon-face-stuck-out-tongue3 {
  &:before {
    content: $icon-face-stuck-out-tongue3;
  }
}
.icon-kissing-face {
  &:before {
    content: $icon-kissing-face;
  }
}
.icon-kissing-face1 {
  &:before {
    content: $icon-kissing-face1;
  }
}
.icon-grinning-face-teeth {
  &:before {
    content: $icon-grinning-face-teeth;
  }
}
.icon-grinning-face-teeth1 {
  &:before {
    content: $icon-grinning-face-teeth1;
  }
}
.icon-angry-face-teeth {
  &:before {
    content: $icon-angry-face-teeth;
  }
}
.icon-angry-face-teeth1 {
  &:before {
    content: $icon-angry-face-teeth1;
  }
}
.icon-worried-face-teeth {
  &:before {
    content: $icon-worried-face-teeth;
  }
}
.icon-worried-face-teeth1 {
  &:before {
    content: $icon-worried-face-teeth1;
  }
}
.icon-grinning-face-teeth2 {
  &:before {
    content: $icon-grinning-face-teeth2;
  }
}
.icon-grinning-face-teeth3 {
  &:before {
    content: $icon-grinning-face-teeth3;
  }
}
.icon-face-open-mouth-eyebrows {
  &:before {
    content: $icon-face-open-mouth-eyebrows;
  }
}
.icon-face-open-mouth-eyebrows1 {
  &:before {
    content: $icon-face-open-mouth-eyebrows1;
  }
}
.icon-face-open-mouth-eyebrows2 {
  &:before {
    content: $icon-face-open-mouth-eyebrows2;
  }
}
.icon-face-open-mouth-eyebrows3 {
  &:before {
    content: $icon-face-open-mouth-eyebrows3;
  }
}
.icon-angry-face-open-mouth-eyebrows {
  &:before {
    content: $icon-angry-face-open-mouth-eyebrows;
  }
}
.icon-angry-face-open-mouth-eyebrows1 {
  &:before {
    content: $icon-angry-face-open-mouth-eyebrows1;
  }
}
.icon-unamused-face-tightly-closed-eyes {
  &:before {
    content: $icon-unamused-face-tightly-closed-eyes;
  }
}
.icon-unamused-face-tightly-closed-eyes1 {
  &:before {
    content: $icon-unamused-face-tightly-closed-eyes1;
  }
}
.icon-sad-face--tightly-closed-eyes {
  &:before {
    content: $icon-sad-face--tightly-closed-eyes;
  }
}
.icon-sad-face--tightly-closed-eyes1 {
  &:before {
    content: $icon-sad-face--tightly-closed-eyes1;
  }
}
.icon-kissing-face2 {
  &:before {
    content: $icon-kissing-face2;
  }
}
.icon-kissing-face3 {
  &:before {
    content: $icon-kissing-face3;
  }
}
.icon-face-closed-meyes {
  &:before {
    content: $icon-face-closed-meyes;
  }
}
.icon-face-closed-meyes1 {
  &:before {
    content: $icon-face-closed-meyes1;
  }
}
.icon-amused-face {
  &:before {
    content: $icon-amused-face;
  }
}
.icon-amused-face1 {
  &:before {
    content: $icon-amused-face1;
  }
}
.icon-amused-face-closed-eyes {
  &:before {
    content: $icon-amused-face-closed-eyes;
  }
}
.icon-amused-face-closed-eyes1 {
  &:before {
    content: $icon-amused-face-closed-eyes1;
  }
}
.icon-amused-face-closed-eyes2 {
  &:before {
    content: $icon-amused-face-closed-eyes2;
  }
}
.icon-amused-face-closed-eyes3 {
  &:before {
    content: $icon-amused-face-closed-eyes3;
  }
}
.icon-face-closed-eyes-open-mouth {
  &:before {
    content: $icon-face-closed-eyes-open-mouth;
  }
}
.icon-face-closed-eyes-open-mouth1 {
  &:before {
    content: $icon-face-closed-eyes-open-mouth1;
  }
}
.icon-face-closed-eyes-open-mouth2 {
  &:before {
    content: $icon-face-closed-eyes-open-mouth2;
  }
}
.icon-face-closed-eyes-open-mouth3 {
  &:before {
    content: $icon-face-closed-eyes-open-mouth3;
  }
}
.icon-face-closed-eyes-open-mouth4 {
  &:before {
    content: $icon-face-closed-eyes-open-mouth4;
  }
}
.icon-face-closed-eyes-open-mouth5 {
  &:before {
    content: $icon-face-closed-eyes-open-mouth5;
  }
}
.icon-laughing-face4 {
  &:before {
    content: $icon-laughing-face4;
  }
}
.icon-laughing-face5 {
  &:before {
    content: $icon-laughing-face5;
  }
}
.icon-smiling-face4 {
  &:before {
    content: $icon-smiling-face4;
  }
}
.icon-smiling-face5 {
  &:before {
    content: $icon-smiling-face5;
  }
}
.icon-grinning-face2 {
  &:before {
    content: $icon-grinning-face2;
  }
}
.icon-grinning-face3 {
  &:before {
    content: $icon-grinning-face3;
  }
}
.icon-sad-face6 {
  &:before {
    content: $icon-sad-face6;
  }
}
.icon-sad-face7 {
  &:before {
    content: $icon-sad-face7;
  }
}
.icon-sad-face8 {
  &:before {
    content: $icon-sad-face8;
  }
}
.icon-sad-face9 {
  &:before {
    content: $icon-sad-face9;
  }
}
.icon-sad-face-closed-eyes {
  &:before {
    content: $icon-sad-face-closed-eyes;
  }
}
.icon-sad-face-closed-eyes1 {
  &:before {
    content: $icon-sad-face-closed-eyes1;
  }
}
.icon-sad-face10 {
  &:before {
    content: $icon-sad-face10;
  }
}
.icon-sad-face11 {
  &:before {
    content: $icon-sad-face11;
  }
}
.icon-smiling-face6 {
  &:before {
    content: $icon-smiling-face6;
  }
}
.icon-smiling-face7 {
  &:before {
    content: $icon-smiling-face7;
  }
}
.icon-astonished-face {
  &:before {
    content: $icon-astonished-face;
  }
}
.icon-astonished-face1 {
  &:before {
    content: $icon-astonished-face1;
  }
}
.icon-astonished-face2 {
  &:before {
    content: $icon-astonished-face2;
  }
}
.icon-astonished-face3 {
  &:before {
    content: $icon-astonished-face3;
  }
}
.icon-face-moustache {
  &:before {
    content: $icon-face-moustache;
  }
}
.icon-face-moustache1 {
  &:before {
    content: $icon-face-moustache1;
  }
}
.icon-face-moustache2 {
  &:before {
    content: $icon-face-moustache2;
  }
}
.icon-face-moustache3 {
  &:before {
    content: $icon-face-moustache3;
  }
}
.icon-face-glasses {
  &:before {
    content: $icon-face-glasses;
  }
}
.icon-face-glasses1 {
  &:before {
    content: $icon-face-glasses1;
  }
}
.icon-face-sunglasses {
  &:before {
    content: $icon-face-sunglasses;
  }
}
.icon-face-sunglasses1 {
  &:before {
    content: $icon-face-sunglasses1;
  }
}
.icon-smirking-face-sunglasses {
  &:before {
    content: $icon-smirking-face-sunglasses;
  }
}
.icon-smirking-face-sunglasses1 {
  &:before {
    content: $icon-smirking-face-sunglasses1;
  }
}
.icon-middle-finger {
  &:before {
    content: $icon-middle-finger;
  }
}
.icon-middle-finger1 {
  &:before {
    content: $icon-middle-finger1;
  }
}
.icon-rock-n-roll {
  &:before {
    content: $icon-rock-n-roll;
  }
}
.icon-rock-n-roll1 {
  &:before {
    content: $icon-rock-n-roll1;
  }
}
.icon-high-five {
  &:before {
    content: $icon-high-five;
  }
}
.icon-high-five1 {
  &:before {
    content: $icon-high-five1;
  }
}
.icon-thumb-up {
  &:before {
    content: $icon-thumb-up;
  }
}
.icon-thumb-up1 {
  &:before {
    content: $icon-thumb-up1;
  }
}
.icon-thumb-down {
  &:before {
    content: $icon-thumb-down;
  }
}
.icon-thumb-down1 {
  &:before {
    content: $icon-thumb-down1;
  }
}
.icon-thumb-up2 {
  &:before {
    content: $icon-thumb-up2;
  }
}
.icon-thumb-up3 {
  &:before {
    content: $icon-thumb-up3;
  }
}
.icon-thumb-down2 {
  &:before {
    content: $icon-thumb-down2;
  }
}
.icon-thumb-down3 {
  &:before {
    content: $icon-thumb-down3;
  }
}
.icon-two-fingers-swipe-left {
  &:before {
    content: $icon-two-fingers-swipe-left;
  }
}
.icon-two-fingers-swipe-left1 {
  &:before {
    content: $icon-two-fingers-swipe-left1;
  }
}
.icon-two-fingers-swipe-right {
  &:before {
    content: $icon-two-fingers-swipe-right;
  }
}
.icon-two-fingers-swipe-right1 {
  &:before {
    content: $icon-two-fingers-swipe-right1;
  }
}
.icon-two-fingers-swipe-up {
  &:before {
    content: $icon-two-fingers-swipe-up;
  }
}
.icon-two-fingers-swipe-up1 {
  &:before {
    content: $icon-two-fingers-swipe-up1;
  }
}
.icon-two-fingers-swipe-down {
  &:before {
    content: $icon-two-fingers-swipe-down;
  }
}
.icon-two-fingers-swipe-down1 {
  &:before {
    content: $icon-two-fingers-swipe-down1;
  }
}
.icon-two-fingers {
  &:before {
    content: $icon-two-fingers;
  }
}
.icon-two-fingers1 {
  &:before {
    content: $icon-two-fingers1;
  }
}
.icon-three-fingers-double-tap {
  &:before {
    content: $icon-three-fingers-double-tap;
  }
}
.icon-three-fingers-double-tap1 {
  &:before {
    content: $icon-three-fingers-double-tap1;
  }
}
.icon-two-fingers-resize-out {
  &:before {
    content: $icon-two-fingers-resize-out;
  }
}
.icon-two-fingers-resize-out1 {
  &:before {
    content: $icon-two-fingers-resize-out1;
  }
}
.icon-two-fingers-resize-in {
  &:before {
    content: $icon-two-fingers-resize-in;
  }
}
.icon-two-fingers-resize-in1 {
  &:before {
    content: $icon-two-fingers-resize-in1;
  }
}
.icon-two-fingers-rotate {
  &:before {
    content: $icon-two-fingers-rotate;
  }
}
.icon-two-fingers-rotate1 {
  &:before {
    content: $icon-two-fingers-rotate1;
  }
}
.icon-one-finger-swipe-left {
  &:before {
    content: $icon-one-finger-swipe-left;
  }
}
.icon-one-finger-swipe-left1 {
  &:before {
    content: $icon-one-finger-swipe-left1;
  }
}
.icon-one-finger-swipe-right {
  &:before {
    content: $icon-one-finger-swipe-right;
  }
}
.icon-one-finger-swipe-right1 {
  &:before {
    content: $icon-one-finger-swipe-right1;
  }
}
.icon-one-finger-swipe-up {
  &:before {
    content: $icon-one-finger-swipe-up;
  }
}
.icon-one-finger-swipe-up1 {
  &:before {
    content: $icon-one-finger-swipe-up1;
  }
}
.icon-one-finger-swipe-down {
  &:before {
    content: $icon-one-finger-swipe-down;
  }
}
.icon-one-finger-swipe-down1 {
  &:before {
    content: $icon-one-finger-swipe-down1;
  }
}
.icon-one-finger {
  &:before {
    content: $icon-one-finger;
  }
}
.icon-one-finger1 {
  &:before {
    content: $icon-one-finger1;
  }
}
.icon-one-finger-double-tap {
  &:before {
    content: $icon-one-finger-double-tap;
  }
}
.icon-one-finger-double-tap1 {
  &:before {
    content: $icon-one-finger-double-tap1;
  }
}
.icon-one-finger-tap {
  &:before {
    content: $icon-one-finger-tap;
  }
}
.icon-one-finger-tap1 {
  &:before {
    content: $icon-one-finger-tap1;
  }
}
.icon-one-finger-tap-hold {
  &:before {
    content: $icon-one-finger-tap-hold;
  }
}
.icon-one-finger-tap-hold1 {
  &:before {
    content: $icon-one-finger-tap-hold1;
  }
}
.icon-thumb-finger-tap {
  &:before {
    content: $icon-thumb-finger-tap;
  }
}
.icon-thumb-finger-tap1 {
  &:before {
    content: $icon-thumb-finger-tap1;
  }
}
.icon-one-finger-click {
  &:before {
    content: $icon-one-finger-click;
  }
}
.icon-one-finger-click1 {
  &:before {
    content: $icon-one-finger-click1;
  }
}
.icon-three-fingers-swipe-left {
  &:before {
    content: $icon-three-fingers-swipe-left;
  }
}
.icon-three-fingers-swipe-left1 {
  &:before {
    content: $icon-three-fingers-swipe-left1;
  }
}
.icon-three-fingers-swipe-right {
  &:before {
    content: $icon-three-fingers-swipe-right;
  }
}
.icon-three-fingers-swipe-right1 {
  &:before {
    content: $icon-three-fingers-swipe-right1;
  }
}
.icon-three-fingers-swipe-up {
  &:before {
    content: $icon-three-fingers-swipe-up;
  }
}
.icon-three-fingers-swipe-up1 {
  &:before {
    content: $icon-three-fingers-swipe-up1;
  }
}
.icon-three-fingers-swipe-down {
  &:before {
    content: $icon-three-fingers-swipe-down;
  }
}
.icon-three-fingers-swipe-down1 {
  &:before {
    content: $icon-three-fingers-swipe-down1;
  }
}
.icon-three-fingers {
  &:before {
    content: $icon-three-fingers;
  }
}
.icon-three-fingers1 {
  &:before {
    content: $icon-three-fingers1;
  }
}
.icon-three-fingers-double-tap2 {
  &:before {
    content: $icon-three-fingers-double-tap2;
  }
}
.icon-three-fingers-double-tap3 {
  &:before {
    content: $icon-three-fingers-double-tap3;
  }
}
.icon-two-fingers-swipe-up2 {
  &:before {
    content: $icon-two-fingers-swipe-up2;
  }
}
.icon-two-fingers-swipe-up3 {
  &:before {
    content: $icon-two-fingers-swipe-up3;
  }
}
.icon-one-finger-double-tap2 {
  &:before {
    content: $icon-one-finger-double-tap2;
  }
}
.icon-one-finger-double-tap3 {
  &:before {
    content: $icon-one-finger-double-tap3;
  }
}
.icon-two-fingers-swipe-down2 {
  &:before {
    content: $icon-two-fingers-swipe-down2;
  }
}
.icon-two-fingers-swipe-down3 {
  &:before {
    content: $icon-two-fingers-swipe-down3;
  }
}
.icon-two-fingers-swipe-right2 {
  &:before {
    content: $icon-two-fingers-swipe-right2;
  }
}
.icon-two-fingers-swipe-right3 {
  &:before {
    content: $icon-two-fingers-swipe-right3;
  }
}
.icon-two-fingers-swipe-left2 {
  &:before {
    content: $icon-two-fingers-swipe-left2;
  }
}
.icon-two-fingers-swipe-left3 {
  &:before {
    content: $icon-two-fingers-swipe-left3;
  }
}
.icon-one-finger-tap2 {
  &:before {
    content: $icon-one-finger-tap2;
  }
}
.icon-one-finger-tap3 {
  &:before {
    content: $icon-one-finger-tap3;
  }
}
.icon-one-finger-tap-hold2 {
  &:before {
    content: $icon-one-finger-tap-hold2;
  }
}
.icon-one-finger-tap-hold3 {
  &:before {
    content: $icon-one-finger-tap-hold3;
  }
}
.icon-one-finger-click2 {
  &:before {
    content: $icon-one-finger-click2;
  }
}
.icon-one-finger-click3 {
  &:before {
    content: $icon-one-finger-click3;
  }
}
.icon-one-finger-swipe-horizontally {
  &:before {
    content: $icon-one-finger-swipe-horizontally;
  }
}
.icon-one-finger-swipe-horizontally1 {
  &:before {
    content: $icon-one-finger-swipe-horizontally1;
  }
}
.icon-one-finger-swipe {
  &:before {
    content: $icon-one-finger-swipe;
  }
}
.icon-one-finger-swipe1 {
  &:before {
    content: $icon-one-finger-swipe1;
  }
}
.icon-two-fingers-double-tap {
  &:before {
    content: $icon-two-fingers-double-tap;
  }
}
.icon-two-fingers-double-tap1 {
  &:before {
    content: $icon-two-fingers-double-tap1;
  }
}
.icon-two-fingers-tap {
  &:before {
    content: $icon-two-fingers-tap;
  }
}
.icon-two-fingers-tap1 {
  &:before {
    content: $icon-two-fingers-tap1;
  }
}
.icon-one-finger-swipe-left2 {
  &:before {
    content: $icon-one-finger-swipe-left2;
  }
}
.icon-one-finger-swipe-left3 {
  &:before {
    content: $icon-one-finger-swipe-left3;
  }
}
.icon-one-finger-swipe-right2 {
  &:before {
    content: $icon-one-finger-swipe-right2;
  }
}
.icon-one-finger-swipe-right3 {
  &:before {
    content: $icon-one-finger-swipe-right3;
  }
}
.icon-one-finger-swipe-up2 {
  &:before {
    content: $icon-one-finger-swipe-up2;
  }
}
.icon-one-finger-swipe-up3 {
  &:before {
    content: $icon-one-finger-swipe-up3;
  }
}
.icon-one-finger-swipe-down2 {
  &:before {
    content: $icon-one-finger-swipe-down2;
  }
}
.icon-one-finger-swipe-down3 {
  &:before {
    content: $icon-one-finger-swipe-down3;
  }
}
.icon-file-png {
  &:before {
    content: $icon-file-png;
  }
}
.icon-file-png1 {
  &:before {
    content: $icon-file-png1;
  }
}
.icon-file-pdf {
  &:before {
    content: $icon-file-pdf;
  }
}
.icon-file-pdf1 {
  &:before {
    content: $icon-file-pdf1;
  }
}
.icon-file-mp3 {
  &:before {
    content: $icon-file-mp3;
  }
}
.icon-file-mp31 {
  &:before {
    content: $icon-file-mp31;
  }
}
.icon-file-mp4 {
  &:before {
    content: $icon-file-mp4;
  }
}
.icon-file-mp41 {
  &:before {
    content: $icon-file-mp41;
  }
}
.icon-file-mov {
  &:before {
    content: $icon-file-mov;
  }
}
.icon-file-mov1 {
  &:before {
    content: $icon-file-mov1;
  }
}
.icon-file-jpg {
  &:before {
    content: $icon-file-jpg;
  }
}
.icon-file-jpg1 {
  &:before {
    content: $icon-file-jpg1;
  }
}
.icon-file-html {
  &:before {
    content: $icon-file-html;
  }
}
.icon-file-html1 {
  &:before {
    content: $icon-file-html1;
  }
}
.icon-file-doc {
  &:before {
    content: $icon-file-doc;
  }
}
.icon-file-doc1 {
  &:before {
    content: $icon-file-doc1;
  }
}
.icon-file-xls {
  &:before {
    content: $icon-file-xls;
  }
}
.icon-file-xls1 {
  &:before {
    content: $icon-file-xls1;
  }
}
.icon-file-docx {
  &:before {
    content: $icon-file-docx;
  }
}
.icon-file-docx1 {
  &:before {
    content: $icon-file-docx1;
  }
}
.icon-file-ppt {
  &:before {
    content: $icon-file-ppt;
  }
}
.icon-file-ppt1 {
  &:before {
    content: $icon-file-ppt1;
  }
}
.icon-file-xlsx {
  &:before {
    content: $icon-file-xlsx;
  }
}
.icon-file-xlsx1 {
  &:before {
    content: $icon-file-xlsx1;
  }
}
.icon-file-m4v {
  &:before {
    content: $icon-file-m4v;
  }
}
.icon-file-m4v1 {
  &:before {
    content: $icon-file-m4v1;
  }
}
.icon-file-mpg {
  &:before {
    content: $icon-file-mpg;
  }
}
.icon-file-mpg1 {
  &:before {
    content: $icon-file-mpg1;
  }
}
.icon-file-zip {
  &:before {
    content: $icon-file-zip;
  }
}
.icon-file-zip1 {
  &:before {
    content: $icon-file-zip1;
  }
}
.icon-file-gif {
  &:before {
    content: $icon-file-gif;
  }
}
.icon-file-gif1 {
  &:before {
    content: $icon-file-gif1;
  }
}
.icon-document-file-png {
  &:before {
    content: $icon-document-file-png;
  }
}
.icon-document-file-png1 {
  &:before {
    content: $icon-document-file-png1;
  }
}
.icon-document-file-pdf {
  &:before {
    content: $icon-document-file-pdf;
  }
}
.icon-document-file-pdf1 {
  &:before {
    content: $icon-document-file-pdf1;
  }
}
.icon-document-file-mp3 {
  &:before {
    content: $icon-document-file-mp3;
  }
}
.icon-document-file-mp31 {
  &:before {
    content: $icon-document-file-mp31;
  }
}
.icon-document-file-mp4 {
  &:before {
    content: $icon-document-file-mp4;
  }
}
.icon-document-file-mp41 {
  &:before {
    content: $icon-document-file-mp41;
  }
}
.icon-document-file-mov {
  &:before {
    content: $icon-document-file-mov;
  }
}
.icon-document-file-mov1 {
  &:before {
    content: $icon-document-file-mov1;
  }
}
.icon-document-file-jpg {
  &:before {
    content: $icon-document-file-jpg;
  }
}
.icon-document-file-jpg1 {
  &:before {
    content: $icon-document-file-jpg1;
  }
}
.icon-document-file-doc {
  &:before {
    content: $icon-document-file-doc;
  }
}
.icon-document-file-doc1 {
  &:before {
    content: $icon-document-file-doc1;
  }
}
.icon-document-file-xls {
  &:before {
    content: $icon-document-file-xls;
  }
}
.icon-document-file-xls1 {
  &:before {
    content: $icon-document-file-xls1;
  }
}
.icon-document-file-docx {
  &:before {
    content: $icon-document-file-docx;
  }
}
.icon-document-file-docx1 {
  &:before {
    content: $icon-document-file-docx1;
  }
}
.icon-document-file-ppt {
  &:before {
    content: $icon-document-file-ppt;
  }
}
.icon-document-file-ppt1 {
  &:before {
    content: $icon-document-file-ppt1;
  }
}
.icon-document-file-xlsx {
  &:before {
    content: $icon-document-file-xlsx;
  }
}
.icon-document-file-xlsx1 {
  &:before {
    content: $icon-document-file-xlsx1;
  }
}
.icon-document-file-txt {
  &:before {
    content: $icon-document-file-txt;
  }
}
.icon-document-file-txt1 {
  &:before {
    content: $icon-document-file-txt1;
  }
}
.icon-document-file-m4v {
  &:before {
    content: $icon-document-file-m4v;
  }
}
.icon-document-file-m4v1 {
  &:before {
    content: $icon-document-file-m4v1;
  }
}
.icon-document-file-mpg {
  &:before {
    content: $icon-document-file-mpg;
  }
}
.icon-document-file-mpg1 {
  &:before {
    content: $icon-document-file-mpg1;
  }
}
.icon-document-file-aac {
  &:before {
    content: $icon-document-file-aac;
  }
}
.icon-document-file-aac1 {
  &:before {
    content: $icon-document-file-aac1;
  }
}
.icon-document-file-wav {
  &:before {
    content: $icon-document-file-wav;
  }
}
.icon-document-file-wav1 {
  &:before {
    content: $icon-document-file-wav1;
  }
}
.icon-document-file-zip {
  &:before {
    content: $icon-document-file-zip;
  }
}
.icon-document-file-zip1 {
  &:before {
    content: $icon-document-file-zip1;
  }
}
.icon-document-file-gif {
  &:before {
    content: $icon-document-file-gif;
  }
}
.icon-document-file-gif1 {
  &:before {
    content: $icon-document-file-gif1;
  }
}
