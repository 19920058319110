// --------------------------------------------------------------------------
// TYPE HEADING BASE
// --------------------------------------------------------------------------
//
// Typography base classes which can be used and extended to bring reusable type styling.
// typeHeading styles that is used to add constant styling to typeHeadings.
//

/**
 * DEPENDENCIES
 * - none
 */


// --------------------------------------------------------------------------
// VARIABLES
// --------------------------------------------------------------------------



// --------------------------------------------------------------------------
// FUNCTIONS
// --------------------------------------------------------------------------



// --------------------------------------------------------------------------
// MIXINS
// --------------------------------------------------------------------------

@mixin type($font-size: $Font-size-b, $line-height: false, $font-weight: false) {
	@include font-size($font-size);

	@if ($line-height != false) {
		line-height: $line-height;
	}

	@if ($font-weight != false) {
		font-weight: $font-weight;
	}
}



// --------------------------------------------------------------------------
// STYLING
// --------------------------------------------------------------------------

//
// Large regular style font styling
//

.typeDisplay,
.display {
	@include type($FontSize-d1, $LineHeight-d1);
}

.display-2 {
	@include type($FontSize-d2, $LineHeight-d2);
}



//
// Heading text styling
//

.typeHeading-1,
.heading-1 {
	@include type($FontSize-h1, $LineHeight-h1, $FontWeight-bold);
}

.typeHeading-2,
.heading-2 {
	@include type($FontSize-h2, $LineHeight-h2, $FontWeight-bold);
}

.typeHeading-3,
.heading-3 {
	@include type($FontSize-h3, $LineHeight-h3, $FontWeight-bold);
}

.typeHeading-4,
.heading-4 {
	@include type($FontSize-h4, $LineHeight-h4, $FontWeight-bold);
}

.typeHeading-5,
.heading-5 {
	@include type($FontSize-h5, $LineHeight-h5, $FontWeight-bold);
}



//
// Heading text styling
//

.typeLede,
.lede {
	@include type($FontSize-c1, $LineHeight-c1, $FontWeight-bold);

	@include media('>=lap') {
		@include type(24px, 1.4);
	}
}



//
// Copy text styling
//

.typeCopy,
.copy {
	@include type($FontSize-c2);
}

.typeCopy-l,
.copy-l {
	@include type($FontSize-c1);
}

.typeCopy-s,
.copy-s {
	@include type($FontSize-c3);
}



//
// Copy text styling
//

.typeLabel,
.label {
	@include type($FontSize-c3, 1.25, $FontWeight-bold);
}


//
// link
//

.typeLink-action {
	// font-weight: $FontWeight-bold;
	@include type($FontSize-c3, 1.2, $FontWeight-bold);
	display: inline-block;
	text-decoration: none;
}

.typeLink-actionBack {
	@include type($FontSize-c3, 1.2, $FontWeight-bold);
	display: inline-block;
	text-decoration: none;

	&:before {
		@include icon-font();
		font-size: 110%;
		content: $icon-chevron-left;
		display: inline-block;
		vertical-align: -2px;
		margin-right: halve($BSU);
	}
}



//
// Text colour alternatives
//

.type--base {
	color: $Colour-baseType;
}

.type--text {
	color: $Colour-text;
}

.type--baseContrast {
	color: $Colour-baseType--contrast;
}

.type--primary {
	color: $Colour-primary;
}

.type--accent {
	color: $Colour-accent;
}
