.Form {}

.Form-field {

   + .Form-field {
      margin-top: $BSU * 3;

      .Form--extraSpace & {
         margin-top: $BSU * 6;
      }
   }
}

.Form-field--flex {
   display: flex;

   .Form-field + .Form-field {
      margin-top: 0;
      margin-left: double($BSU);
   }

   + .Form-field--flex {
      margin-top: double($BSU);
   }
}

.Form-label {
   font-weight: $FontWeight-bold;
}

.Form-input {
   @include font-size($FontSize-c2);
   display: block;
   width: 100%;
   padding: double($BSU) triple($BSU);

   background-color: $Colour-white;
   border: 1px solid $Colour-border;
   border-radius: 5px;

   .Form-label +& {
      margin-top: $BSU;
   }
}

.Form-inputMulti {
   @include font-size($FontSize-c2);
   display: block;
   width: 100%;
   height: ($FontSize-c2 * 5) + triple($BSU * 2);
   padding: double($BSU) triple($BSU);

   background-color: $Colour-white;
   border: 1px solid $Colour-border;
   border-radius: 5px;

   .Form-label +& {
      margin-top: $BSU;
   }
}

.Form-inputMulti--s {
   @include font-size($FontSize-c2);
   display: block;
   width: 100%;
   height: ($FontSize-c2 * 2) + triple($BSU * 2);
   padding: double($BSU) triple($BSU);

   background-color: $Colour-white;
   border: 1px solid $Colour-border;
   border-radius: 5px;

   .Form-label +& {
      margin-top: $BSU;
   }
}

.Form-inputPill {
   display: inline-block;
   margin-right: $BSU;
   margin-bottom: $BSU;

   input[type=radio],
   input[type=checkbox] {
      display: none;

      &:checked {
         + label {
            background-color: $Colour-border;
         }
      }
   }

   label {
      @include font-size($FontSize-c2);
      display: block;
      padding: ($BSU) double($BSU);
      border: 1px solid $Colour-border;
      border-radius: $br;
      cursor: pointer;
   }
}

.Form-select {
   @include font-size($FontSize-c2);
   display: block;
   width: 100%;
   padding: double($BSU) triple($BSU);
   height: 47px;

   background-color: $Colour-white;
   border: 1px solid $Colour-border;
   border-radius: 5px;

   .Form-label +& {
      margin-top: $BSU;
   }
}

.Form-submit {
   @include button($FontSize-c2, double($BSU) ($BSU * 4));

	background-color: $Colour-primary;
	border-color: $Colour-primary;
	color: $Colour-white;

	&:focus,
	&:hover {
		color: $Colour-white;
	}
}
