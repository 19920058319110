// --------------------------------------------------------------------------
// CLEARFIX BASE
// --------------------------------------------------------------------------
//
// Clearfix is used on parents to contain floated children.
//

/**
 * DEPENDENCIES
 * - none
 */


// --------------------------------------------------------------------------
// VARIABLES
// --------------------------------------------------------------------------


// --------------------------------------------------------------------------
// FUNCTIONS
// --------------------------------------------------------------------------


// --------------------------------------------------------------------------
// MIXINS
// --------------------------------------------------------------------------

@mixin clearfix() {
	&:after {
		content: "";
		display: table;
		clear: both;
	}
}


// --------------------------------------------------------------------------
// STYLING
// --------------------------------------------------------------------------

.clearfix {
	@include clearfix();
}