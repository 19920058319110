// --------------------------------------------------------------------------
// PARAGRAPH ELEMENT
// --------------------------------------------------------------------------

/**
 * Intro text
 *
 *
 * DEPENDENCIES
 * - base/type
 *
 *
 * USED WITH
 * -
 *
 */

 // Styleguide 22.1.0.



// --------------------------------------------------------------------------
// VARIABLES
// --------------------------------------------------------------------------



// --------------------------------------------------------------------------
// FUNCTIONS
// --------------------------------------------------------------------------



// --------------------------------------------------------------------------
// MIXINS
// --------------------------------------------------------------------------



// --------------------------------------------------------------------------
// EXTENDABLES
// --------------------------------------------------------------------------



// --------------------------------------------------------------------------
// STYLING
// --------------------------------------------------------------------------


p {
	@include type($FontSize-c2);

	+ p {
		margin-top: $FontSize-c2;
	}

	h2 +& {
		margin-top: $BSU;
	}
}
