// --------------------------------------------------------------------------
// BUTTON BASE
// --------------------------------------------------------------------------
//
// The .button class can be used to style markup to look like a button.
//

/**
 * DEPENDENCIES
 * - none
 */


// --------------------------------------------------------------------------
// VARIABLES
// --------------------------------------------------------------------------


// --------------------------------------------------------------------------
// FUNCTIONS
// --------------------------------------------------------------------------



// --------------------------------------------------------------------------
// MIXINS
// --------------------------------------------------------------------------

@mixin button($fontSize: $FontSize-c2, $padding: double($BSU) ($BSU * 4)) {
	@include font-size($fontSize);
	line-height: 24px;
	display: inline-block;
	padding: $padding;

	text-decoration: none;
	text-shadow: none;
	border: 1px solid $Colour-primary;
	border-radius: 5px;
	cursor: pointer;

	background-color: $Colour-primary;
	// border-color: $Colour-primary;
	color: $Colour-white;

	&:focus,
	&:hover {
		color: $Colour-white;
	}
}



// --------------------------------------------------------------------------
// STYLING
// --------------------------------------------------------------------------

.button {
	@include button();
}

.button--ghost {
	background-color: transparent;
	color: $Colour-primary;

	&:focus,
	&:hover {
		color: $Colour-primary;
		background-color: $Colour-border;
	}
}

.button--s {
	@include font-size($FontSize-c3);
	padding: $BSU double($BSU);
}
