// --------------------------------------------------------------------------
// SPACING BASE
// --------------------------------------------------------------------------
//
// Spacing to be used to add spacers between content
//

/**
 * DEPENDENCIES
 * - none
 */


// --------------------------------------------------------------------------
// VARIABLES
// --------------------------------------------------------------------------


// --------------------------------------------------------------------------
// FUNCTIONS
// --------------------------------------------------------------------------



// --------------------------------------------------------------------------
// MIXINS
// --------------------------------------------------------------------------



// --------------------------------------------------------------------------
// STYLING
// --------------------------------------------------------------------------

.mt {
	margin-top: $Base-spacing-unit;
}

.mt-2,
.mt\+ {
	margin-top: double($Base-spacing-unit);
}


.mt-3 {
	margin-top: ($Base-spacing-unit) * 3;
}

.mt-4,
.mt\+\+ {
	margin-top: double(double($Base-spacing-unit));
}

.mt-6 {
	margin-top: ($Base-spacing-unit * 6);
}

.mt-9 {
	margin-top: ($Base-spacing-unit * 9);
}

.mr\+\+ {
	margin-right: double($BSU * 2);
}

.ml-g\+ {
	margin-left: double($Column-gutter);
}

.mb-2,
.mb\+ {
	margin-bottom: double($Base-spacing-unit);
}
