// --------------------------------------------------------------------------
// TYPE ELEMENT
// --------------------------------------------------------------------------

/**
 * Text element styling
 *
 *
 * DEPENDENCIES
 * - 
 *
 *
 * USED WITH
 * -
 * 
 */

 // Styleguide 23.1.0.



// --------------------------------------------------------------------------
// VARIABLES
// --------------------------------------------------------------------------



// --------------------------------------------------------------------------
// FUNCTIONS
// --------------------------------------------------------------------------



// --------------------------------------------------------------------------
// MIXINS
// --------------------------------------------------------------------------



// --------------------------------------------------------------------------
// EXTENDABLES
// --------------------------------------------------------------------------



// --------------------------------------------------------------------------
// STYLING
// --------------------------------------------------------------------------

@if $use-element-type == true {
	b,
	strong {
	  font-weight: $FontWeight-bold;
	}


	i,
	cite,
	em,
	var,
	address,
	dfn  {
		font-style: italic;
	}
}