// --------------------------------------------------------------------------
// PAGE COMPONENT
// --------------------------------------------------------------------------
//
// Page

/**
 * DEPENDENCIES
 * - none
 */

// --------------------------------------------------------------------------
// VARIABLES
// --------------------------------------------------------------------------


// --------------------------------------------------------------------------
// FUNCTIONS
// --------------------------------------------------------------------------


// --------------------------------------------------------------------------
// MIXINS
// --------------------------------------------------------------------------



// --------------------------------------------------------------------------
// STYLING
// --------------------------------------------------------------------------

 .Page {
	min-height: 100vh;
}

.Page-contain {
   overflow-x: hidden;
}

.Page-navbar {
   padding-top: double($BSU);
   padding-bottom: double($BSU);
   border-bottom: 1px solid $Colour-border;

   a {
      color: $Colour-black;
      text-decoration: none;
   }

   li {
      + li {
         margin-left: 24px;
      }
   }
}

.Page-navbarWrap {
   max-width: $Grid-maxWidth;
   margin-left: auto;
   margin-right: auto;
}

.Page-panel {
   padding-top: 40px;
   padding-bottom: 40px;
}

.Page-panel--class {
   padding-top: 0;
}

.Page-panel--paint {
   background-color: $Colour-bg;
}

.Page-actionBar {
   display: flex;
   position: relative;
   justify-content: space-between;
   align-items: center;
}

.Page-actionBarStart {
   // position: absolute;
   // left: 0;
   // top: 3px;
}

.Page-actionBarCenter {
   flex-grow: 2;
   text-align: center;

   position: absolute;
   top: 0;
   left: 50%;
   transform: translateX(-50%);
}

.Page-actionBarEnd {}

.Page-titlePill {
   @include font-size(18px);
   display: inline-block;
   padding: $BSU triple($BSU);

   border-bottom-left-radius: 8px;
   border-bottom-right-radius: 8px;
   background-color: $Colour-bg;
}
