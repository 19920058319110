// --------------------------------------------------------------------------
// MIXINS
// --------------------------------------------------------------------------

@mixin font-size($font-size: $FontSize-base) {
	font-size: $font-size + 0px;
	font-size: convert-to-rem($font-size);
}

@mixin conditional($variable, $state: true) {
	@if $state == false {
		@if $variable == false {
			@content;
		}
	} @else {
		@if $variable != false {
			@content;
		}
	}
}