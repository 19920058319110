// --------------------------------------------------------------------------
// PAINT BASE
// --------------------------------------------------------------------------
//
// Adds a background colour
//

/**
 * DEPENDENCIES
 * - none
 */


// --------------------------------------------------------------------------
// VARIABLES
// --------------------------------------------------------------------------


// --------------------------------------------------------------------------
// FUNCTIONS
// --------------------------------------------------------------------------



// --------------------------------------------------------------------------
// MIXINS
// --------------------------------------------------------------------------



// --------------------------------------------------------------------------
// STYLING
// --------------------------------------------------------------------------

.paint {
	background-color: $Colour-baseType;
	color: $Colour-baseType--contrast;
}

.paint-bg {
	background-color: $Colour-bg;
}

.paint-english {
	background-color: $Colour-english !important;
}

.paint-maths {
	background-color: $Colour-maths !important;
}

.paint-reading {
	background-color: $Colour-reading !important;
}

.paint-word {
	background-color: $Colour-word !important;
}

.paint-grammar {
	background-color: $Colour-grammar !important;
}

.paint-movement {
	background-color: $Colour-movement !important;
}

.paint-topic {
	background-color: $Colour-topic !important;
}

.paint-phonics {
	background-color: $Colour-phonics !important;
}
