.LoginCard {
   width: 100%;
   max-width: 24rem;
   margin-left: auto;
   margin-right: auto;

   padding: 24px;

   background-color: $Colour-bg;
   border-radius: 8px;

   @include media('>=tab') {
      padding: 32px;
   }

   @include media('>=lap') {
      padding: 48px;
   }
}

.LoginCard-submitWrap {
   display: flex;
   justify-content: space-between;
   align-items: center;
}
