.Navbar {
   display: flex;
   justify-content: space-between;
   align-items: center;
   padding-left: $Column-gutter;
	padding-right: $Column-gutter;
}

.Navbar-nav {
   list-style: none;
   padding-left: 0;

   li {
      display: inline-block;
   }
}

.Navbar-brand {

}

.Navbar-brand--logo {
   text-indent: -9999px;
   width: 100px;
   height: 38px;
   // background: url('./../img/Form_logo.png') left center no-repeat;
   background-repeat: no-repeat;
   background-position: left center;
   background-size: auto 100%;
}
