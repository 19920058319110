// --------------------------------------------------------------------------
// VARIABLES
// --------------------------------------------------------------------------
// This collection of variables are applicable across a range of style files.


// --------------------------------------------------------------------------
// Colour Variables
// --------------------------------------------------------------------------

$Colour-black: #000 !default;
$Colour-white: #fff !default;

$Colour-base: #444 !default;
$Colour-baseType: $Colour-base !default;
$Colour-baseType--contrast: $Colour-white;

$Colour-text: #777 !default;

$Colour-primary: #4255FF !default;
$Colour-primary--darken: #1eb5ad !default;

$Colour-secondary: #549CEF !default;
$Colour-secondary--darken: #4784CB !default;

$Colour-accent: #4255FF !default;

$Colour-border: rgba($Colour-base, .14) !default;

$Colour-bg: #efefef !default;

$Colour-inactive: #999 !default;
$Colour-error: #EA4E35 !default;


$Colour-english: #87C6D6;
$Colour-maths: #E09E64;
$Colour-reading: #D67482;
$Colour-word: #77BAA7;
$Colour-grammar: #BBD6B1;
$Colour-movement: #F4D06C;
$Colour-topic: #726598;
$Colour-phonics : #B2CA54;



// --------------------------------------------------------------------------
// Type Variables
// --------------------------------------------------------------------------
// TODO: Think of and document a better naming structure for type
// Display
// Heading
// Copy

//
// Font
//

@import url(https://fonts.googleapis.com/css?family=Karla:400,400italic,700,700italic);


//
// Font Families
//

$FontFamily-heading: "Karla", "Helvetica Neue", Helvetica, sans-serif !default;
$FontFamily-copy: "Karla", "Helvetica Neue", Helvetica, sans-serif !default;
$FontFamily-mono: Consolas, "Andale Mono WT", "Andale Mono", Courier, monospace !default;


//
// Font Weights
//

$FontWeight-bold: 700 !default;
// $FontWeight-semi: 500 !default;
$FontWeight-normal: 400 !default;


//
// Display Sizing
//

$FontSize-d1: 200px !default;
$LineHeight-d1: 1 !default;

$FontSize-d2: 50px !default;
$LineHeight-d2: 1.15 !default;

$FontSize-d3: 40px !default;
$LineHeight-d3: 1.4 !default;

$FontSize-d4: 28px !default;
$LineHeight-d4: 1.5 !default;


//
// Heading Sizing
//

$FontSize-h1: 38px !default;
$LineHeight-h1: 1.25 !default;

$FontSize-h2: 26px !default;
$LineHeight-h2: 1.3 !default;

$FontSize-h3: 22px !default;
$LineHeight-h3: 1.4 !default;

$FontSize-h4: 18px !default;
$LineHeight-h4: 1.5 !default;

$FontSize-h5: 15px !default;
$LineHeight-h5: 1.5 !default;


//
// Copy Sizing
//

$FontSize-base: 18px !default;
$LineHeight-base: 1.5 !default;

$FontSize-c1: 24px !default;
$LineHeight-c1: $LineHeight-base !default;

$FontSize-c2: $FontSize-base !default;
$FontSize-c3: 14px !default;
$FontSize-c4: 12px !default;



$Margin-heading: 0;
$c-typeHeading-marginFirstChild: false;



// --------------------------------------------------------------------------
// Spacing Variables
// --------------------------------------------------------------------------

$Base-spacing-unit: 6px !default;
$BSU: $Base-spacing-unit;
$br: 5px;


// --------------------------------------------------------------------------
// Breakpoint Variables
// --------------------------------------------------------------------------

$breakpoints: (
  'mob': 320px,
  'tab': 768px,
  'lap': 1024px,
  'desk': 1400px
);


// --------------------------------------------------------------------------
// Layout Variables
// --------------------------------------------------------------------------

$Layout-boxSizing: border-box;


// --------------------------------------------------------------------------
// VARIABLES
// --------------------------------------------------------------------------

$Grid-namespace: Grid;
$Grid-maxWidth: 1280px;

$Row-namespace: Grid-row;
$Row-margin: 0 auto;

$Column-namespace: Grid-column;
$Column-gutter: 12px;
