// --------------------------------------------------------------------------
// MEDIA ELEMENT COMPONENT
// --------------------------------------------------------------------------
//
// Media grid

/**
 * DEPENDENCIES
 * - none
 */

// --------------------------------------------------------------------------
// VARIABLES
// --------------------------------------------------------------------------

$mediaElement-margin: $Base-spacing-unit * 2 !default;


// --------------------------------------------------------------------------
// FUNCTIONS
// --------------------------------------------------------------------------


// --------------------------------------------------------------------------
// MIXINS
// --------------------------------------------------------------------------


// --------------------------------------------------------------------------
// STYLING
// --------------------------------------------------------------------------

.Media,
.Media-body {
	overflow: hidden;
	_overflow: visible;
	zoom: 1;
}

.Media--tabUp {
	@include media('<tab') {

	}
}

.Media--link {
	display: block;
	text-decoration: none;

	color: $Colour-black;
}

.Media-image,
.Media-imageAlt {
	img {
		display: block;
	}
}

.Media-image,
.Media-imageMiddle {
	margin-right: $mediaElement-margin;

	.Media--m & {
		margin-right: double($mediaElement-margin);
	}

	.Media--l & {
		margin-right: triple($mediaElement-margin);
	}
}

.Media-image {
	float: left;

	.Media--tabUp & {
		@include media('<tab') {
			float: none;
		}
	}
}

.Media-imageAlt,
.Media-imageAltMiddle {
	margin-left: $mediaElement-margin;

	.Media--m & {
		margin-left: double($mediaElement-margin);
	}

	.Media--l & {
		margin-left: triple($mediaElement-margin);
	}
}

.Media-imageAlt {
	float: right;
}

.Media-bodyMiddle,
.Media-imageMiddle {
	display: inline-block;
	vertical-align: middle;
}

.Media-imageMiddle img {
	vertical-align: bottom;
}



.MediaFlex {
	display: flex;
}

.MediaFlex-image {
	margin-right: $mediaElement-margin;

	.Media--m & {
		margin-right: double($mediaElement-margin);
	}

	.Media--l & {
		margin-right: triple($mediaElement-margin);
	}
}

.MediaFlex-imageAlt {
	margin-left: $mediaElement-margin;

	.Media--m & {
		margin-left: double($mediaElement-margin);
	}

	.Media--l & {
		margin-left: triple($mediaElement-margin);
	}
}

.MediaFlex--middle {
	align-items: center;
}
