.Comments {
   max-height: 90vh;
   overflow-y: scroll;
   padding: triple($BSU);

   border: 1px solid $Colour-border;
   background-color: $Colour-white;
   border-radius: $br;
}

.Comments-list {
   // margin-top: triple($BSU);
   // padding-top: triple($BSU);

   .Comments-form +& {
      // border-top: 1px solid $Colour-border;
      margin-top: double($BSU);
   }
}

.Comments-listItem {
   + .Comments-listItem {
      margin-top: double($BSU);
   }
}

.Comments-listMessage {
   padding: $BSU triple($BSU);

   background-color: $Colour-bg;
   // box-shadow: 0 0 3px rgba($Colour-black, .2);
   border-radius: 15px 15px 15px 0;
}

.Comments-listMeta {
   @include type($FontSize-c3);
   margin-top: halve($BSU);
}
