// Based on Griddle
// https://github.com/necolas/griddle/blob/master/_griddle.scss

// --------------------------------------------------------------------------
// GRID COMPONENT
// --------------------------------------------------------------------------
//
// The grid is used for layout and structure. It should ideally be used to
// wrap elements rather than alongside elements/components.

/**
 * DEPENDENCIES
 * - none
 */


// --------------------------------------------------------------------------
// VARIABLES
// --------------------------------------------------------------------------


// --------------------------------------------------------------------------
// FUNCTIONS
// --------------------------------------------------------------------------


// --------------------------------------------------------------------------
// MIXINS
// --------------------------------------------------------------------------

@mixin grid() {
	// @include clearfix(); // trying non float grid
	max-width: $Grid-maxWidth;
	margin-left: auto;
	margin-right: auto;
	// padding-left: $Column-gutter;
	// padding-right: $Column-gutter;

	letter-spacing: -0.31em;
    text-rendering: optimizespeed;

	.#{$Grid-namespace} {
		margin-left: -($Column-gutter);
		margin-right: -($Column-gutter);
	}
}

@mixin grid-column() {
	// float: left; // trying non float grid
	display: inline-block;
	width: 100%;
	padding-left: $Column-gutter;
	padding-right: $Column-gutter;
	vertical-align: top;

	letter-spacing: normal;
	word-spacing: normal;
	text-rendering: auto;
}

@mixin grid-row() {
	display: block;
	width: 100%;
	padding-left: $Column-gutter;
	padding-right: $Column-gutter;
	vertical-align: top;

	letter-spacing: normal;
	word-spacing: normal;
	text-rendering: auto;

	margin-left: auto;
	margin-right: auto;
}



// --------------------------------------------------------------------------
// STYLING
// --------------------------------------------------------------------------

.#{$Grid-namespace} {
	@include grid();
}

.#{$Grid-namespace}-stack {
	+ .#{$Grid-namespace}-stack {
		margin-top: triple($Column-gutter);
	}
}

.Grid-splashPaint {
	position: relative;
	padding-top: triple($Column-gutter);
	padding-bottom: triple($Column-gutter);
	background-color: $Colour-bg;

	&:before,
	&:after {
		content: '';
		background-color: $Colour-bg;
		content: "";
		height: 100%;
		width: 100%;
		position: absolute;
		top: 0;
		z-index: 0;
	}

	&:before {
		left: 0;
		transform: translateX(-100%);
	}

	&:after {
		right: 0;
		transform: translateX(100%);
	}
}

.#{$Grid-namespace}--stack {
	+ .#{$Grid-namespace}--stack {
		margin-top: 40px;
	}
}

.opera:-o-prefocus,
.grid {
    word-spacing: -0.43em;
}

.#{$Column-namespace} {
	@include grid-column();
}

.#{$Row-namespace} {
	@include grid-row();
}
