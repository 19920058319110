// BUTTON ELEMENTS
//
// Some styling for base html elements.
// Elements include html, element and a * catch all box-sizing.
//
// DEPENDENCIES </br>
// - none
//
// EXTENDS </br>
// - none
//
// VARIABLES </br>
// -
//
// .button--primary				- Primary button modifier used to catch the users eye
// :hover :focus				- For consistancy the :hover and :focus styling is the same for the default an button mods
//
// Markup:
// <button href="#" class="{$modifiers}">Hello</button>
//
// Styleguide 17.0.0.



// --------------------------------------------------------------------------
// VARIABLES
// --------------------------------------------------------------------------



// --------------------------------------------------------------------------
// FUNCTIONS
// --------------------------------------------------------------------------



// --------------------------------------------------------------------------
// MIXINS
// --------------------------------------------------------------------------



// --------------------------------------------------------------------------
// EXTENDABLES
// --------------------------------------------------------------------------



// --------------------------------------------------------------------------
// STYLING
// --------------------------------------------------------------------------

@if $use-element-button == true {
	button {
		@include button();
	}
}
