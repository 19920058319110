// --------------------------------------------------------------------------
// UTILS BASE
// --------------------------------------------------------------------------
//
// Utility classes that help with tiny comon cases
//

/**
 * DEPENDENCIES
 * - none
 */


// --------------------------------------------------------------------------
// VARIABLES
// --------------------------------------------------------------------------


// --------------------------------------------------------------------------
// FUNCTIONS
// --------------------------------------------------------------------------



// --------------------------------------------------------------------------
// MIXINS
// --------------------------------------------------------------------------



// --------------------------------------------------------------------------
// STYLING
// --------------------------------------------------------------------------

.center {
	text-align: center;
}

.alignRight {
	text-align: right;
}

.middle {
	margin-left: auto!important;
	margin-right: auto!important;
}

.block {
	display: block;
}

.flex-alignBottom {
	align-self: flex-end;
}

.flex-alignCenter {
	align-items: center;
}

.pullRight {
	float: right;
}
